<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <h1 class="mb-0"><strong>{{getTitleUI}}</strong></h1>
                </div>
                <button
                  class="btn th-custom-color px-4 rounded ml-3"
                  @click="irACrearTratamiento"
                  :disabled="!hasPermisionNuevoTratamiento"
                >
                  Nuevo
                </button>
              </div>
              <div class="col-sm-3 offset-sm-4">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input id="filterbyState"
                      type="checkbox"
                      :checked="estado"
                      v-model="estado"
                      @change="listarRegistros">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="por_pagina"
                    :options="pagina_opciones"
                    @change="listarRegistros">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text"
                      class="form-control"
                      v-model="filter"
                      autocomplete="off"
                      placeholder="Buscar"
                      @keyup="filtrarBusqueda"
                    >
                  </div>
                </div>
              </div>
            </div>
            <b-table
              class="table table-striped table-hover"
              :per-page="por_pagina"
              :fields="getFieldsTable"
              :items="listaTabla"
              @filtered="onFiltered"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template v-if="estado" #cell(editar)="items">
                <i v-if="items.item.tratamiento_cierre_id > 0"
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                  :title="items.item.estado === 'Facturado' ? items.item.vinculado : messageCierre"
                ></i>
                <router-link v-else-if="hasPermisionModificarTratamiento"
                  :to="{ name: 'internacion-edit', params: {id: encrypt(items.item.id)}}"
                >
                  <i
                    class="nav-icon i-Pen-5 font-weight-bold"
                    :class="hasPermisionModificarTratamiento? 'text-success':''"
                    style="cursor: pointer"
                  ></i>
                </router-link>
                <i v-else
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(ver)>
                <i
                  v-if="hasPermisionVerTratamiento"
                  class="nav-icon i-Eye font-weight-bold"
                  :class="hasPermisionVerTratamiento? 'text-warning':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(imprimir)="data">
                <i
                class="nav-icon i-File-Download font-weight-bold"
                :class="hasPermisionImprimirTratamiento ? 'text-info' : 'text-muted'"
                :style="!hasPermisionImprimirTratamiento ? '' : 'cursor: pointer'"
                @click="hasPermisionImprimirTratamiento && verPDF(data.item)"
              >
              </i>
              </template>
              <template v-if="estado" #cell(eliminar)="items">
                <i v-if="items.item.tratamiento_cierre_id > 0"
                  class="nav-icon i-Close-Window font-weight-bold"
                  style="cursor: pointer"
                  :title="items.item.estado === 'Facturado' ? items.item.vinculado : messageCierre"
                  ></i>
                <i v-else
                  @click="onClickEliminarIcon(items.item)"
                  class="nav-icon i-Close-Window font-weight-bold"
                  :class="hasPermisionEliminarTratamiento? 'text-danger':''"
                  style="cursor: pointer"
                ></i>
              </template>

              <template #cell(estado)="data">
                <button
                  class="btn"
                  :class="getEstadoClass(data.item.estado)"
                >
                  {{getEstadoText(data.item.estado)}}
                </button>
              </template>
              <template #cell(enfermeria)="items">
                  <i
                    @click="onClickAtencionEnfermeria(items.item)"
                    style="cursor: pointer"
                    class="h4 i-Girl"
                  ></i>
              </template>
            </b-table>
            <b-modal id="modal-eliminar"
              :title="'Seguro que quieres eliminar la Internacion - N° '+selectedNro+'?'"
              hide-footer>
              <div class="row">
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                    Eliminar
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-outline-dark"
                  @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
                </div>
              </div>
            </b-modal>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarRegistros"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalPrintInternacion :itemSelected="itemSelected"/>
    <the-modal-tipo-internacion ref="tipoInternacionModal" :tipoInternacion="tipoInternacion" />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';
import { FUNCIONALIDADES } from '@/utils/globalConventions';
import modalPrintInternacion from '../components/ModalPrintInternacion.vue';
import TheModalTipoInternacion from '../components/TheModalTipoInternacion.vue';

export default {
  name: 'index',
  components: {
    modalPrintInternacion,
    TheModalTipoInternacion,
  },
  data() {
    return {
      estado: true,
      por_pagina: 100,
      pagina_opciones: [50, 100],
      delayTimeout: null,
      fields: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'ver', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'imprimir', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'fecha', label: 'Fecha', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '7%' }, sortable: true,
        },
        {
          key: 'paciente', label: 'Paciente', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '16%' }, sortable: true,
        },
        {
          key: 'medicos', label: 'Medico', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '16%' }, sortable: true,
        },
        {
          key: 'categoria_tratamientos', label: 'Tratamiento', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'pieza', label: 'Pieza', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '8%' }, sortable: true,
        },
        {
          key: 'cama', label: 'Cama', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '8%' }, sortable: true,
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'enfermeria', label: 'Enfermeria', thClass: 'th-custom-color', class: 'text-center px-0', thStyle: { width: '8%' },
        },
      ],
      listaTabla: [],
      isLoading: true,
      pagina_actual: 1,
      totalFilas: 0,
      filter: '',
      selectedId: null,
      selectedNro: null,
      messageCierre: 'Accion no permitida, este cuenta ha sido cerrada',
      itemSelected: null,
      mostrarModal: false,
      has_active_tipo_tratamiento: false,
      tipoInternacion: [],
    };
  },
  created() {
    this.listarRegistros();
  },
  methods: {
    irACrearTratamiento() {
      if (this.has_active_tipo_tratamiento) {
        this.irATipoInternacion();
      } else {
        this.$router.push({ name: 'internacion-create' });
      }
    },
    irATipoInternacion() {
      this.$refs.tipoInternacionModal.$bvModal.show('tipoInternacionModal');
    },
    async listarRegistros() {
      this.isLoading = true;
      try {
        const params = {
          page: this.pagina_actual,
          per_page: this.por_pagina,
          estado: this.estado,
          filterKey: this.filter,
        };
        const response = await axios.get('clinic/tratamientos', { params });
        const arrayTratamientos = response.data.data.tratamientos.data;
        this.totalFilas = response.data.data.tratamientos.total;
        this.listaTabla = arrayTratamientos;
        this.has_active_tipo_tratamiento = response.data.data.has_active_tipo_tratamiento;
        this.tipoInternacion = response.data.data.tipo_internacion;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarRegistros, DELAY);
    },
    onFiltered(filteredItems) {
      this.totalFilas = filteredItems.length;
      this.pagina_actual = 1;
    },
    encrypt(id) {
      return btoa(id);
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    eliminarRegistroLocalmente() {
      this.listaTabla = this.listaTabla.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/tratamientos/delete/${this.selectedId}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    verPDF(item) {
      this.itemSelected = item;
      this.$bvModal.show('modalPrintInternacion');
    },
    onClickEliminarIcon(item) {
      if (this.hasPermisionEliminarTratamiento) {
        this.setItems(item);
        this.$bvModal.show('modal-eliminar');
      }
    },
    getEstadoText(text) {
      let result = text;
      switch (text) {
        case 'Cerrado':
          result = 'Liquidacion';
          break;
        case 'Precierre':
          result = 'Pre Liquidacion';
          break;
        case 'Precierre_sin_recuento':
          result = 'Abierto';
          break;
        default:
          break;
      }
      return result;
    },
    getEstadoClass(text) {
      let cssClass = '';
      switch (text) {
        case 'Abierto':
          cssClass = 'bg-abierto';
          break;
        case 'Cerrado':
          cssClass = 'bg-cerrado';
          break;
        case 'Precierre':
          cssClass = 'bg-precierre';
          break;
        case 'Facturado':
          cssClass = 'bg-facturado';
          break;
        case 'Precierre_sin_recuento':
          cssClass = 'bg-precierre';
          break;
        default:
          break;
      }
      return cssClass;
    },
    onClickAtencionEnfermeria(item) {
      this.$router.push({
        name: 'internacion-atencion-enfermeria-edit',
        params: { id: this.encrypt(item.id), paciente_id: this.encrypt(item.paciente_id) },
      });
    },
  },
  computed: {
    ...mapGetters('main', [
      'getFuncionalidades',
      'hasPermisionNuevoTratamiento',
      'hasPermisionModificarTratamiento',
      'hasPermisionVerTratamiento',
      'hasPermisionEliminarTratamiento',
      'hasPermisionImprimirTratamiento',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    getTitleUI() {
      const ITEM = this.getFuncionalidades.find((el) => el.id === FUNCIONALIDADES.INTERNACION);
      if (ITEM !== null) {
        return ITEM.descripcion;
      }
      return '';
    },
    getFieldsTable() {
      let customFields = this.fields;
      const ITEM = this.getFuncionalidades.find((el) => (
        el.id === FUNCIONALIDADES.ENFERMERIA
      )) ?? null;

      if (this.hasPermisionVerTratamiento === false) {
        customFields = customFields.filter((obj) => (
          obj.key !== 'ver'
        ));
      }
      if (ITEM === null) {
        customFields = customFields.filter((obj) => (
          obj.key !== 'enfermeria'
        ));
      }
      return customFields;
    },
  },
};
</script>

<style scoped>
.custom-top-padding {
  padding-top: 5.53px;
}
.bg-abierto {
  background: #f8cb9e;
}
.bg-cerrado {
  background: #cf1102;
  color: white;
}
.bg-precierre {
  background: #f3d753;
}
.bg-facturado {
  background: #288f04;
  color: white;
}
</style>
